import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Solve It Once",
  "date": "2018-01-23",
  "promo": "grids",
  "description": "What a naughty little beast can teach us about eliminating technical debt from our code and lives.",
  "color": "#d04f32"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`What a naughty little beast can teach us about eliminating technical debt from our code and our lives.`}</em></p>
    <p>{`I have an awesome little Manchester Terrier named Sam. She's the best dog a guy could ask for. Good with the kids, isn't yappy or annoying like most small dogs, loves to play and keeps my knees warm during shows. But Sam has one major downside: like most Terriers, she loves to `}<em parentName="p">{`bolt`}</em>{`. `}</p>
    <p>{`Neighborhood kids come over? `}<em parentName="p">{`zip!`}</em>{` she's out the door and enjoying her adventure before the first cuss word can form in my mind. Whenever I need to go check the mail I'll carefully scan the living room, making sure the beast is nowhere in sight. But as soon as I open that front door just three inches, she appears out of nowhere and dashes through. `}</p>
    <h2>{`The Breaking Point`}</h2>
    <p>{`I've put up with my little escapist for `}<em parentName="p">{`five years`}</em>{`. FIVE YEARS. During which I made several half-hearted attempts at solving the problem. More walks, dog training, neighbor training, signs, etc. Nothing worked and I'd just go back to dealing with it. This last year since working from home it got especially annoying. I'd be working in my office, finally in that precious `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/mastery-through-play/"
      }}>{`state of flow`}</a>{` when all of a sudden from out my window I hear a distant `}<em parentName="p">{`rough!`}</em>{` `}<em parentName="p">{`rough!`}</em>{` Flow shattered, I'd grumble and retrieve my dog. It finally broke me - I `}<em parentName="p">{`had`}</em>{` to fix the Sam problem.`}</p>
    <h2>{`That's It!`}</h2>
    <p>{`I set my mind to solving this blasted problem once and for all, and there was nothing in the world that could stop me. I brainstormed all sorts of ideas, the majority of which were legal and humane. I bought one of those electric shock collars but couldn't bring myself to using it on her. I tried a non-hurty sonar one that supposedly produces a dog-repelling sound at close proximity - talk about a scam. I even entertained the idea of inventing some kind of dog-catching robot.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/dog-catcher.jpg",
        "alt": "dog catching robot"
      }}></img></p>
    <p>{`Finally I realized that if my brain couldn't defeat my pup, perhaps my opposable thumbs could. I devised a contraption in the doorway that would be easy enough for a human to step over, but too hard for Sam to get through or over. With a wicked laugh I walked through the isles of Home Depot and found what I needed. Sam eyed me curiously - I let her watch - as I installed the end of her freedom: eleven bungee cords strung tight between rings drilled into the wood frame.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/contraption.png",
        "alt": "gate contraption"
      }}></img></p>
    <p><strong parentName="p">{`Sam hasn't escaped since!`}</strong></p>
    <p>{`I consider this one of my life's greatest achievements. I never have to worry again about my best little friend getting hit by a car. And I'm writing this article right now in a wonderful state of uninterrupted flow.`}</p>
    <h2>{`Living with Unsolved Problems isn't Stoic`}</h2>
    <p>{`This really got me thinking - why do we put up endlessly with unsolved problems? Things that cause us a lot of pain and frustration? Especially as developers, we put up with a ton of problems of our own making. Dog-slow webpack builds. Terrible home-grown abstractions. CSS that produces Jira bug tickets faster than you can squash them. Rotten areas of the codebase that use three different JS frameworks because nobody dares touch that crap. These snags cost us every time they're tripped over. New features take exponentially longer to release. Existing features get no love for the `}<a parentName="p" {...{
        "href": "/post/polish"
      }}>{`the polish`}</a>{` they need.`}</p>
    <p>{`But instead of eliminating problems once and for all, we let them fester. We let them `}<em parentName="p">{`strangle`}</em>{` our productivity. We label them `}<strong parentName="p">{`Technical Debt`}</strong>{` and `}<em parentName="p">{`ask for permission`}</em>{` to solve them, knowing full well that decision makers have no idea what we're talking about. Because getting shot down by management kinda feels like `}<em parentName="p">{`"oh well I tried"`}</em>{`. It's a quick way to feel proactive without having to tackle the hard problems -  plus now we can blame someone else for our situation. Victim mode FTW!`}</p>
    <p>{`No joke - I worked at a place once that `}<strong parentName="p">{`banned`}</strong>{` the phrase "Technical Debt". Developers weren't allowed to say it. Execs were tired of hearing about it and just wanted people to solve their own problems.`}</p>
    <h2>{`Weak Skills are just Unsolved Problems`}</h2>
    <p>{`We sometimes do the same thing with our dev skills. Weak skills come with a steep tax. Skilled UI devs don't settle for struggling endlessly with CSS. They master it. They don't have to stop what they're doing and look up Flexbox or Grid all the time. The good news is a weak skill is just another problem to be solved. Mastering the fundamentals of your craft pays off huge in the long run. This is why I created `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` and `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` - so devs can solve their frontent layout problems once and for all. It's been awesome watching my students master this stuff and build amazing things on their own.`}</p>
    <h2>{`When to Stop Drop and Solve`}</h2>
    <p>{`Early computer scientist `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Willem_van_der_Poel"
      }}>{`Willem van der Poel`}</a>{` came up with a rule he called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Zero_one_infinity_rule"
      }}>{`Zero, One, or Infinity`}</a>{` (ZOI). The idea is that for frequency of occurrence there are only three categories:`}</p>
    <ul>
      <li parentName="ul">{`something can `}<strong parentName="li">{`never`}</strong>{` happen`}</li>
      <li parentName="ul">{`something can only ever happen `}<strong parentName="li">{`once`}</strong></li>
      <li parentName="ul">{`something can happen `}<strong parentName="li">{`infinite`}</strong>{` times`}</li>
    </ul>
    <p>{`This zero, one, or many rule has had a huge impact in our industry. We see it in foreign keys in database design. It's been a fundamental in UX design ever since Alan Cooper's classic `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Inmates-Are-Running-Asylum-Products/dp/0672326140"
      }}>{`The Inmates are Running the Asylum`}</a>{`. It's also a fantastic rule for knowing when to solve problems - both in your life and in your code.`}</p>
    <p><strong parentName="p">{`Problem encountered 0 times`}</strong><br parentName="p"></br>{`
`}{`Don't worry about it yet. Sometimes us devs get overwhelmed thinking we have to learn every bit of tech out there, when in reality most of it exists to solve problems we don't currently have. You can be an expert UI developer by only focusing on mastering the fundamentals and `}<a parentName="p" {...{
        "href": "/post/what-not-to-learn/"
      }}>{`picking up only the things you actually need`}</a>{`.`}</p>
    <p><strong parentName="p">{`Problem encountered 1 time`}</strong><br parentName="p"></br>{`
`}{`Make a note, but don't sic your mind on coming up with a solution. Many problems are one-time events that won't bite you again. (dog references intended)`}</p>
    <p><strong parentName="p">{`Problem encountered 2 times`}</strong><br parentName="p"></br>{`
`}{`As soon as a problem gets encountered twice (twice by you, or twice by two different people in your team/family) then you're in `}<strong parentName="p">{`infinity`}</strong>{` territory. If it can happen twice, it can happen a thousand times. This is the right time to solve it good and solve it once.`}</p>
    <h2>{`Solve it Once`}</h2>
    <p>{`When you set out to solve a problem, the biggest temptation is to do a `}<strong parentName="p">{`quick fix`}</strong>{`. Something that mitigates the pain a little. You know you've fallen prey to this "band-aid" mindset if you hear yourself saying things like `}<em parentName="p">{`for now`}</em>{` or `}<em parentName="p">{`V1`}</em>{` or `}<em parentName="p">{`first pass`}</em>{`. Temporary fixes just aren't worth it. How many times have you encountered a `}<em parentName="p">{`//TODO:`}</em>{` in a project from someone who doesn't even work there anymore? Their temporary fix is now one more thing you have to understand to actually fix the core problem. `}</p>
    <p><em parentName="p">{`Hacks upon hacks, breaks the devs' backs`}</em></p>
    <p>{`Here's a better way to work: `}<strong parentName="p">{`if it happens twice, solve it once`}</strong>{`. Like, actually solve it. So that the problem has no chance of wasting another engineer's afternoon ever again. You can get all fancy if you want - keeping a spreadsheet of every occurrence of every problem. But it's easy enough to just say "hey team, the wonky dropdown component took me four hours to debug, anyone else run into this?" If so - it's fixin' time and you're up! If you delay the task to the dusty backlog you'll never get around to it. Solve it now while it's fresh on your mind. Solve it good so nobody has to trip over it or even think about it ever again.`}</p>
    <p>{`Imagine how productive your life and project would be if problems were fixed as they came up, never allowed to impact more than twice?`}</p>
    <h2>{`What's your Sam?`}</h2>
    <p>{`Think about an unsolved problem - your Sam - that you've been dealing with forever. Stop reading this right now and go take care of it. `}<strong parentName="p">{`Solve it good, solve it once.`}</strong></p>
    <p><strong parentName="p">{`P.S.`}</strong>{` In case you're wondering from the illustrations, Sam does indeed look more like a bat than a dog. Now that I've solved the escaping problem she's a happy, safe bat-dog.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sam-hoodie.jpg",
        "alt": "Sam picture"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      